import React from "react";
import {
    BrowserRouter as Router,
    // Navigate,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import Home from "./Home/Home.js";
import People from "./People/People.js"

import Navbar from "./ReusableComponents/Navbar.js"

function Components() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/team" element={<People />} />
                
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        </Router>
    );
}

export default Components;