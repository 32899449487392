export const founders = [
    {
      name: "Mark Evgnev",
      position: "Co-Founder and CEO",
      image: "/team/mark.png",
      linkedin: "https://www.linkedin.com/in/mevgenev",
      email: "mark@desync.ai",
      funFacts: [
        "Gray hair at 22.",
        "8th startup.",
        "14 different internships.",
        "Absolute workaholic.",
        "Can read 600 words per minute.",
        "Definitely American.",
        "Loves caffeine (maybe a little too much).",
      ],
    },
    {
      name: "Maks Kubicki",
      position: "Co-Founder and CTO",
      image: "/team/maks.png",
      linkedin: "https://www.linkedin.com/in/maksymilian-kubicki-35401b241/",
      email: "maks@desync.ai",
      funFacts: [
        "Maginn group for molecular simulations.",
        "Broke into servers competitively.",
        "Made a 3D ski app solo.",
        "Loves rockets, margherita pizza, and Python.",
        "Fan of plain margherita pizza, steak, white rice, and salmon sushi.",
        "❤️ = Łódź.",
        "🚀 Loves rockets.",
        "🕵️ Fan of spies.",
        "🍕 Plain margherita pizza lover.",
      ],
    },
    {
        name: "Jackson Ballow",
        position: "Co-Founder and CPO",
        image: "/team/jackson.png",
        linkedin: "https://www.linkedin.com/in/jackson-ballow-9888271bb",
        email: "jackson@desync.ai",
        funFacts: [
          "Pursuing CS MS at Purdue with a specialization in ML.",
          "Loves long enthusiastic walks.",
          "Bengal Bouts (boxing semi-finalist).",
          "Goated React manipulator.",
          "Will break your ankles on the court.",
          "Loves fishing.",
          "Studying Desync sleeping patterns.",
        ],
      },
  ];
  
  export const advisors = [  
    {
      name: "Michael J Frank",
      title: "Operations Advisor",
      image: "/team/michael.png",
      linkedin: "https://www.linkedin.com/in/michael-j-frank-boardmember/",
      description: "Decades of experience from Wall Street floors to VC funds. Principal at Neuvation Ventures.",
      website: "https://www.gildre.com/about",
    },
    {
      name: "Malcolm Werchota",
      title: "AI Advisor",
      image: "/team/malcolm.png",
      linkedin: "https://www.linkedin.com/in/malcolmwerchota/",
      description: "Known in Europe as Big Chief of GenAI in Business and founder of Coating AI.",
      website: "https://werchota.ai/",
    },
    {
      name: "Jamal Wilson",
      title: "Legal Advisor",
      image: "/team/jamal.png",
      linkedin: "https://www.linkedin.com/in/jamaljwilson/",
      description: "Advisor who has been with the team since day one.",
      website: "https://www.foxrothschild.com/jamal-j-wilson#:~:text=Jamal%20provides%20strategic%20legal%20advice,a%20broad%20range%20of%20transactions.",
    },
  ];
  