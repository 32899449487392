import { useState } from "react";
import { founders, advisors } from "./peopleData";
import { Linkedin, Link as LinkIcon } from "lucide-react";

function People() {
    const [hoveredFounder, setHoveredFounder] = useState(null);
    const [funFacts, setFunFacts] = useState({});

    const getRandomFunFact = (person) => {
        if (!person.funFacts || person.funFacts.length === 0) return null;
        const randomIndex = Math.floor(Math.random() * person.funFacts.length);
        return person.funFacts[randomIndex];
    };

    const handleMouseEnter = (person) => {
        const fact = getRandomFunFact(person);
        setHoveredFounder(person.name);
        setFunFacts((prev) => ({ ...prev, [person.name]: fact }));
    };

    const handleMouseLeave = () => {
        setHoveredFounder(null);
    };

    return (
        <section className="text-white px-6 py-20 max-w-7xl mx-auto">
            {/* Hero */}
            <div className="mb-20 text-left max-w-4xl ml-7">
                <h1 className="text-[2.75rem] md:text-[4rem] font-bold leading-tight">
                    Meet the team building Desync AI
                </h1>
                <p className="text-gray-400 text-lg mt-4">
                    The founders and advisors behind Desync AI
                </p>
            </div>

            {/* Founders */}
            <div className="mb-24">
                <div className="mb-8 border-l-4 border-blue-600 pl-4 ml-7">
                    <h2 className="text-3xl font-bold">Founders</h2>
                    <p className="text-gray-400 text-sm mt-1">The core team building Desync AI</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 place-items-center">
                    {founders.map((person, idx) => (
                        <div key={idx} className="flex flex-col items-center text-center relative">
                            <div
                                className="relative"
                                onMouseEnter={() => handleMouseEnter(person)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img
                                    src={person.image}
                                    alt={person.name}
                                    className="w-[20rem] h-[25rem] object-cover rounded-lg border-blue-600 shadow-md border-[.25px]"
                                />
                                {/* Animated Fun Fact Overlay */}
                                <div
                                    className={`absolute top-0 left-0 w-full h-full rounded-lg flex items-center justify-center p-4 transition-all duration-300 ease-in-out backdrop-blur-sm
                                        ${hoveredFounder === person.name ? "opacity-100 scale-100 bg-black/80" : "opacity-0 scale-95 pointer-events-none"}`}
                                >
                                    <p className="text-sm text-gray-100 text-center">
                                        {funFacts[person.name]}
                                    </p>
                                </div>
                            </div>
                            <h3 className="mt-4 text-xl font-semibold">{person.name}</h3>
                            <p className="text-gray-400 text-sm">{person.position}</p>
                            {person.linkedin && (
                                <a
                                    href={person.linkedin}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="mt-2 text-gray-400 hover:text-blue-500 transition"
                                >
                                    <Linkedin size={18} />
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Advisors */}
            <div>
                <div className="mb-8 border-l-4 border-purple-600 pl-4 ml-7">
                    <h2 className="text-3xl font-bold">Advisors</h2>
                    <p className="text-gray-400 text-sm mt-1">
                    The people helping guide us on our journey
                    </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 place-items-center">
                    {advisors.map((person, idx) => (
                    <div key={idx} className="flex flex-col items-center text-center relative">
                        <img
                        src={person.image}
                        alt={person.name}
                        className="w-[20rem] h-[25rem] object-cover rounded-lg border-[.25px] border-purple-600 shadow-md"
                        />
                        <h3 className="mt-4 text-xl font-semibold">{person.name}</h3>
                        <div className="flex gap-3 mt-2">
                        {person.linkedin && (
                            <a
                            href={person.linkedin}
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-400 hover:text-blue-500 transition"
                            >
                            <Linkedin size={18} />
                            </a>
                        )}
                        {person.website && (
                            <a
                            href={person.website}
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-400 hover:text-blue-400 transition"
                            >
                            <LinkIcon size={18} />
                            </a>
                        )}
                        </div>
                    </div>
                    ))}
                </div>
            </div>

        </section>
    );
}

export default People;
