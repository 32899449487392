import { GithubIcon } from "lucide-react";

function GitHubButton() {
    return (
        <a 
            href="https://github.com/Desync-ai/desync.ai_tools" 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center bg-gray-800 hover:bg-gray-700 text-gray-300 px-4 py-2 rounded-lg text-sm font-medium transition border border-gray-700"
        >
            <GithubIcon className="h-4 w-4 text-gray-400 mr-2" />
            GitHub
        </a>
    );
}

export default GitHubButton;
