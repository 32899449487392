import { useState } from "react";
import { NavLink } from "react-router-dom";
import GitHubButton from "./GitHubButton.js";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const navLinkStyle = ({ isActive }) =>
        isActive ? "text-white font-semibold" : "text-[#A2A2A2] hover:text-white";

    return (
        <nav className="text-gray-400 p-4 pt-7">
            <div className="flex items-center justify-between">
                {/* Logo Section */}
                <div className="flex items-center space-x-2 ml-[5%]">
                    <NavLink to="/home" className={navLinkStyle}>
                        <img src="/logo.svg" alt="Daytona Logo" className="h-8 w-auto mr-2" />
                    </NavLink>
                    {/* Hide "/" when navbar collapses */}
                    <span className="text-[#A2A2A2] mr-2 md:inline hidden">/</span>

                    <div className="hidden md:flex space-x-4">
                        <NavLink to="/home" className={navLinkStyle}>
                            HOME
                        </NavLink>
                        <NavLink to="/team" className={navLinkStyle}>
                            TEAM
                        </NavLink>
                        <a
                            href="https://lore.desync.ai"
                            target="_blank"
                            rel="noreferrer"
                            className="text-[#A2A2A2] hover:text-white"
                        >
                            BLOG
                        </a>
                        <a
                            href="https://docs.desync.help/pages/overview/"
                            target="_blank"
                            rel="noreferrer"
                            className="text-[#A2A2A2] hover:text-white"
                        >
                            DOCUMENTATION
                        </a>
                    </div>
                </div>

                {/* Right Section - Sign In & GitHub Button (Desktop Only) */}
                <div className="ml-auto flex items-center space-x-4 mr-[5%]">
                    <a
                        href="https://beta.desync.ai/login"
                        target="_blank"
                        rel="noreferrer"
                        className="hidden md:block bg-[#007FFF] bg-opacity-20 text-[#0080FF] px-4 py-2 rounded-md hover:bg-opacity-30 transition"
                    >
                        Sign in
                    </a>
                    <div className="hidden md:block">
                        <GitHubButton />
                    </div>
                </div>

                {/* Hamburger Menu (Mobile) */}
                <button
                    className="md:hidden text-white focus:outline-none mr-[5%]"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    ☰
                </button>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="md:hidden flex flex-col items-center space-y-4 mt-4">
                    <NavLink to="/home" className={navLinkStyle}>
                        HOME
                    </NavLink>
                    <NavLink to="/team" className={navLinkStyle}>
                        TEAM
                    </NavLink>
                    <a
                            href="https://lore.desync.ai"
                            target="_blank"
                            rel="noreferrer"
                            className="text-[#A2A2A2] hover:text-white"
                        >
                            BLOG
                        </a>
                    <a
                        href="https://docs.desync.help/pages/overview/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[#A2A2A2] hover:text-white"
                    >
                        DOCUMENTATION
                    </a>
                    <a
                        href="https://beta.desync.ai/login"
                        target="_blank"
                        rel="noreferrer"
                        className="bg-[#007FFF] bg-opacity-20 text-[#0080FF] px-4 py-2 rounded-md hover:bg-opacity-30 transition"
                    >
                        Sign in
                    </a>

                    <div className="flex space-x-4">
                        <GitHubButton />
                    </div>
                </div>
            )}
        </nav>
    );
}

export default Navbar;
