import { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Clipboard } from "lucide-react";

function PrimaryFunctions() {
    const [selectedFunction, setSelectedFunction] = useState("basic");
    const [copiedCode, setCopiedCode] = useState(false);
    const [copiedCommand, setCopiedCommand] = useState(false);

    const codeSnippets = {
        basic: `from desync_search import DesyncClient

user_api_key = "YOUR_DESYNC_KEY"
client = DesyncClient(user_api_key)

# Stealth search
stealth_data = client.search("https://example.com")

# Inspect PageData fields
print('Stealth search results:')
print("URL:", stealth_data.url)
print("Number of internal links:", len(stealth_data.internal_links))
print("Number of external links:", len(stealth_data.external_links))
print("Text content length:", len(stealth_data.text_content))`,

        list: `from desync_search import DesyncClient

user_api_key = "YOUR_DESYNC_KEY"
client = DesyncClient(user_api_key)

# List minimal records from past searches
records = client.list_available()
for r in records:
    print(r.id, r.url, r.search_type, r.created_at)
    
# Fetch full text, HTML, and links from a stored record
details = client.pull_data(record_id=123)
if details and details[0].success:
    print("HTML length:", len(details[0].html_content))`,

        crawl: `from desync_search import DesyncClient

user_api_key = "YOUR_DESYNC_KEY"
client = DesyncClient(user_api_key)
        
# Crawl up to 3 levels deep on the same domain
all_pages = client.crawl(
    start_url="https://www.example.com",
    max_depth=3,
    scrape_full_html=False,     # Set True if you need HTML content
    remove_link_duplicates=True # Avoid repeated links
)

print(f"Discovered {len(all_pages)} unique pages")

for page in all_pages:
    print("URL:", page.url, "| Depth:", getattr(page, "depth", None))`,

        bulk: `from desync_search import DesyncClient

user_api_key = "YOUR_DESYNC_KEY"
client = DesyncClient(my_api_key)

# A list of URLs to search
target_list = [
    "https://example.com",
    "https://another-example.net",
    # ... up to 1000
]

# Initiate the bulk search
bulk_info = client.bulk_search(target_list=target_list, extract_html=False)

print("Message:", bulk_info.get("message"))
print("Bulk Search ID:", bulk_info.get("bulk_search_id"))
print("Total links scheduled:", bulk_info.get("total_links"))
print("Cost charged:", bulk_info.get("cost_charged"))`
    };

    // Simulated PageData Object
    const pageDataObject = [
        { key: "id", value: "12345" },
        { key: "url", value: "https://example.com" },
        { key: "domain", value: "example.com" },
        { key: "timestamp", value: "2024-02-25T12:34:56Z" },
        { key: "bulk_search_id", value: "67890" },
        { key: "search_type", value: "stealth_search" },
        { key: "text_content", value: "Extracted text content..." },
        { key: "html_content", value: "<html>...</html>" },
        { key: "internal_links", value: "[/about, /contact]" },
        { key: "external_links", value: "[https://external.com]" },
        { key: "latency_ms", value: "350ms" },
        { key: "complete", value: "true" },
        { key: "created_at", value: "2024-02-25T12:35:00Z" },
    ];

    const handleCopyCode = () => {
        navigator.clipboard.writeText(codeSnippets[selectedFunction]);
        setCopiedCode(true);
        setTimeout(() => setCopiedCode(false), 2000);
    };

    const handleCopyCommand = () => {
        navigator.clipboard.writeText("pip install desync_search");
        setCopiedCommand(true);
        setTimeout(() => setCopiedCommand(false), 2000);
    };

    return (
        <section className="max-w-7xl w-full px-6 py-16 mt-[3em]">
            <h2 className="text-[3em] mb-4 text-center md:text-left">Primary Functions</h2>
            <p className="text-gray-400 mb-8 text-center md:text-left">
                Quick, Simple Implementation. Check out the
                <a 
                    href="https://docs.desync.help/pages/overview/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-400 font-medium ml-1"
                >
                    full documentation 📖
                </a>
            </p>

            <div className="flex flex-col md:flex-row justify-between items-start space-y-10 md:space-y-0">
                
                {/* Left - Function List */}
                <div className="md:w-[35%] flex flex-col"> 
                    {[
                        { key: "basic", icon: "✳️", title: "Single Search", desc: "Perform a stealth search (costs 10 credits) or a test search (costs 1 credit). Bypass CAPTCHAs more reliably." },
                        { key: "crawl", icon: "🔍", title: "Crawl", desc: "Recursively gather internal URLs from a domain." },
                        { key: "bulk", icon: "⚙️", title: "Bulk Search", desc: "Search multiple URLs in parallel." },
                        { key: "list", icon: "🔗", title: "List Records and Pull Data", desc: "Retrieve past search records and pull their full text, HTML, and links." },
                        { key: "test", icon: "📄", title: "Search Results", desc: "Understanding the PageData object." },
                    ].map(({ key, icon, title, desc }, index) => (
                        <div 
                            key={key}
                            className={`cursor-pointer transition 
                                ${selectedFunction === key ? "bg-gray-900 text-white" : "hover:bg-gray-800 hover:text-white text-gray-300"}`}
                            onClick={() => setSelectedFunction(key)}
                        >
                            <div className="p-4">
                                <h3 className="text-lg font-semibold flex items-center space-x-2">
                                    <span>{icon}</span>
                                    <span>{title}</span>
                                </h3>
                                <p className="text-gray-400 text-sm mt-1">{desc}</p>
                            </div>
                            {index !== 4 && <hr className="border-gray-700 w-full" />}
                        </div>
                    ))}
                </div>

                {/* Right - Install Command & Code Block (Wider) */}
                <div className="md:w-[60%] w-full flex flex-col space-y-5">
                    
                    {/* Install Command */}
                    <div className="flex items-center border border-gray-700 p-3 rounded-lg w-full max-w-full overflow-hidden sm:flex-nowrap">
                        <div className="flex items-center space-x-2 truncate">
                            <img 
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png" 
                                alt="Python Logo" 
                                className="h-6 w-auto"
                            />
                            <span className="text-gray-300 text-sm md:text-base truncate whitespace-nowrap">
                                pip install desync_search
                            </span>
                        </div>
                        <button 
                            className="text-gray-400 hover:text-white transition p-1 rounded ml-auto"
                            onClick={handleCopyCommand}
                        >
                            {copiedCommand ? "✅" : <Clipboard size={16} />}
                        </button>
                    </div>

                    {/* Code Block OR PageData Display */}
                    {selectedFunction === "test" ? (
                        <div className="border border-gray-700 p-5 rounded-lg bg-gray-900 text-gray-300">
                            <h3 className="text-lg font-semibold text-white">Understanding the PageData Object</h3>
                            <ul className="mt-2 text-sm font-mono">
                                {pageDataObject.map(({ key, value }) => (
                                    <li key={key}>
                                        <span className="font-semibold text-white">{key}:</span> {value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className="relative border border-gray-700 p-5 rounded-lg">
                            <SyntaxHighlighter language="python" style={oneDark} showLineNumbers className="rounded-md text-sm w-full overflow-x-auto">
                                {codeSnippets[selectedFunction]}
                            </SyntaxHighlighter>
                            <button 
                                className="absolute top-2 right-2 text-gray-400 hover:text-white transition p-1 rounded"
                                onClick={handleCopyCode}
                            >
                                {copiedCode ? "✅" : <Clipboard size={16} />}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default PrimaryFunctions;
