import { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Clipboard } from "lucide-react";
import PrimaryFunctions from "./PrimaryFunctions.js";

function Home() {
  const [copiedCode, setCopiedCode] = useState(false);
  const [copiedCommand, setCopiedCommand] = useState(false);
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [role, setRole] = useState("Developer");

  const codeSnippet = `from desync_search.core import DesyncClient

user_api_key = "YOUR_DESYNC_KEY"
client = DesyncClient(user_api_key)`;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(codeSnippet);
    setCopiedCode(true);
    setTimeout(() => setCopiedCode(false), 2000);
  };

  const handleCopyCommand = () => {
    navigator.clipboard.writeText("pip install desync_search");
    setCopiedCommand(true);
    setTimeout(() => setCopiedCommand(false), 2000);
  };

  return (
    <section className="text-white min-h-screen flex flex-col items-center px-6">

      {/* Hero Section */}
      <div className="max-w-7xl w-full flex flex-col md:flex-row items-center justify-between space-y-10 md:space-y-0 py-12 mt-[11%]">
        
        {/* Left - Heading & Buttons */}
        <div className="md:w-[55%] text-center md:text-left">
          <h1 className="text-4xl md:text-6xl font-bold">The API To The Internet</h1>
          <p className="text-gray-400 mt-7">
            We are building an easy way for developers to programmatically
            access any page on the internet at scale.
          </p>
          <p className="text-lg font-semibold mt-2 text-[#C1C1C1]">
            <strong>Automate workflows, extract data, the use cases are infinite</strong>
          </p>

          <div className="mt-6 flex space-x-4 justify-center md:justify-start">
            <a href="https://beta.desync.ai/login" target="_blank" rel="noreferrer" className="bg-[#0080FF] text-white px-6 py-3 rounded-md hover:bg-[#0066cc] transition">
              Get An API Key
            </a>
            <a href="https://docs.desync.help/pages/overview/" target="_blank" rel="noreferrer" className="border border-gray-500 px-6 py-3 rounded-md hover:border-white transition flex items-center space-x-2">
              <span>View Docs</span>
              <span>→</span>
            </a>
          </div>
        </div>

        {/* Right - Code Boxes */}
        <div className="md:w-[40%] w-full flex flex-col space-y-4">
          <div className="flex items-center border border-gray-700 p-3 rounded-lg justify-between">
            <div className="flex items-center space-x-2">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png" alt="Python Logo" className="h-6 w-auto" />
              <span className="text-gray-300 text-sm md:text-base break-all">pip install desync_search</span>
            </div>
            <button className="text-gray-400 hover:text-white transition p-1 rounded" onClick={handleCopyCommand}>
              {copiedCommand ? "✅" : <Clipboard size={14} />}
            </button>
          </div>

          <div className="border border-gray-700 p-4 rounded-lg relative w-full overflow-x-auto">
            <SyntaxHighlighter
              language="python"
              style={{
                ...oneDark,
                "pre[class*='language-']": { backgroundColor: "transparent", overflowX: "auto", whiteSpace: "pre-wrap" },
                "code[class*='language-']": { backgroundColor: "transparent" },
              }}
              showLineNumbers={true}
              lineNumberStyle={{ color: "#888", fontSize: "12px", paddingRight: "10px" }}
              className="rounded-md text-sm"
            >
              {codeSnippet}
            </SyntaxHighlighter>
            <button className="absolute top-2 right-2 text-gray-400 hover:text-white transition p-1 rounded" onClick={handleCopyCode}>
              {copiedCode ? "✅" : <Clipboard size={14} />}
            </button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="max-w-7xl w-full px-6 py-16 mt-[3em]">
          <h2 className="text-[3em] mb-4">Current Features</h2>
          <p className="text-gray-400 mb-8">The easiest way to automate web workflows without hassle</p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-0 border border-gray-700">
          <div className="p-6 border-b border-r border-gray-700 flex flex-col justify-center">
              <span className="text-2xl">🕵️‍♂️</span>
              <h3 className="text-xl mt-3 text-left">Stealth Search</h3>
              <p className="text-gray-400 mt-1 text-left">"Human-like" visits to bypass CAPTCHAs</p>
          </div>
          <div className="p-6 border-b border-r border-gray-700 flex flex-col justify-center">
              <span className="text-2xl">⚙️</span>
              <h3 className="text-xl mt-3 text-left">Dev Friendly</h3>
              <p className="text-gray-400 mt-1 text-left">3 lines of Python</p>
          </div>
          <div className="p-6 border-b border-gray-700 flex flex-col justify-center">
              <span className="text-2xl">🛡️</span>
              <h3 className="text-xl mt-3 text-left">Reliable</h3>
              <p className="text-gray-400 mt-1 text-left">5,000,000+ pages extracted</p>
          </div>
          <div className="p-6 border-b border-r border-gray-700 flex flex-col justify-center">
              <span className="text-2xl">📈</span>
              <h3 className="text-xl mt-3 text-left">Scalable</h3>
              <p className="text-gray-400 mt-1 text-left">50,000 concurrent operations</p>
          </div>
          <div className="p-6 border-b border-r border-gray-700 flex flex-col justify-center">
              <span className="text-2xl">🔍</span>
              <h3 className="text-xl mt-3 text-left">Transparent</h3>
              <p className="text-gray-400 mt-1 text-left">No hidden fees</p>
          </div>
          <div className="p-6 border-b border-gray-700 flex flex-col justify-center">
              <span className="text-2xl">🧠</span>
              <h3 className="text-xl mt-3 text-left">Realistic</h3>
              <p className="text-gray-400 mt-1 text-left">Render full JavaScript</p>
          </div>
          </div>
      </section>


      {/* Primary Functions */}
      <PrimaryFunctions />

      {/* Comparison Table */}
      <section className="max-w-7xl w-full px-4 md:px-6 py-10 md:py-16">
        <h2 className="text-3xl md:text-4xl mb-2 text-white">
          How Desync Compares to Alternatives
        </h2>
        <p className="text-gray-400 mb-8">
          See what Desync offers compared to everybody else
        </p>

        {/* No horizontal scroll needed because we hide columns instead */}
        <div className="w-full">
          <table className="w-full border-collapse text-white">
            {/* Table Header */}
            <thead>
              <tr className="border-b border-gray-700">
                <th className="py-4 w-1/6"></th>

                {/* Desync AI */}
                <th className="py-4 w-1/6 font-semibold text-center">
                  <img
                    src="/logo.svg"
                    alt="Desync AI"
                    className="mx-auto h-8 md:h-10 w-auto"
                  />
                </th>

                {/* Browser Base */}
                <th className="py-4 w-1/6 font-semibold text-center hidden md:table-cell">
                  <img
                    src="/images/browser_base.svg"
                    alt="Browser Base"
                    className="mx-auto h-8 md:h-10 w-auto object-contain"
                  />
                </th>

                {/* Browser Use */}
                <th className="py-4 w-1/6 font-semibold text-center hidden lg:table-cell">
                  <img
                    src="/images/browser_use.png"
                    alt="Browser Use"
                    className="mx-auto h-8 md:h-10 w-auto object-contain"
                  />
                </th>

                {/* Scraper API */}
                <th className="py-4 w-1/6 font-semibold text-center hidden xl:table-cell">
                  <img
                    src="/images/scraperapi.jpeg"
                    alt="Scraper API"
                    className="mx-auto h-8 md:h-10 w-auto object-contain rounded-sm"
                  />
                </th>

                {/* Apify */}
                <th className="py-4 w-1/6 font-semibold text-center hidden xl:table-cell">
                  <img
                    src="/images/apify.jpeg"
                    alt="Apify"
                    className="mx-auto h-11 w-auto object-contain"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {/* (1) Minimum Spend */}
              <tr className="border-b border-gray-700">
                <td className="p-3 md:p-4 font-semibold">Minimum spend</td>
                {/* Desync AI */}
                <td className="p-3 md:p-4 bg-gray-800 text-center">
                  <div className="uppercase text-xs mb-1">Starting from</div>
                  <div className="text-xl md:text-3xl font-bold leading-tight">$1</div>
                  <div className="text-gray-300 text-xs">per 10k credits</div>
                </td>

                {/* Browser Base */}
                <td className="p-3 md:p-4 text-center hidden md:table-cell">
                  <div className="uppercase text-xs mb-1">Starting from</div>
                  <div className="text-xl font-bold">$39</div>
                  <div className="text-gray-300 text-xs">USD/mo</div>
                </td>

                {/* Browser Use */}
                <td className="p-3 md:p-4 text-center hidden lg:table-cell">
                  <div className="uppercase text-xs mb-1">Starting from</div>
                  <div className="text-xl font-bold">$30</div>
                  <div className="text-gray-300 text-xs">USD/mo</div>
                </td>

                {/* Scraper API */}
                <td className="p-3 md:p-4 text-center hidden xl:table-cell">
                  <div className="uppercase text-xs mb-1">Starting from</div>
                  <div className="text-xl font-bold">$49</div>
                  <div className="text-gray-300 text-xs">USD/mo</div>
                </td>

                {/* Apify */}
                <td className="p-3 md:p-4 text-center hidden xl:table-cell">
                  <div className="uppercase text-xs mb-1">Starting from</div>
                  <div className="text-xl font-bold">$39</div>
                  <div className="text-gray-300 text-xs">USD/mo</div>
                </td>
              </tr>

              {/* (2) Pay as you go */}
              <tr className="border-b border-gray-700">
                <td className="p-3 md:p-4">Pay as you go</td>
                <td className="p-3 md:p-4 bg-gray-800 text-center">
                  <span className="text-green-400 text-xl md:text-2xl font-bold">
                    ✓
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden md:table-cell">
                  <span className="text-red-500 text-xl md:text-2xl font-bold">
                    ✕
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden lg:table-cell">
                  <span className="text-red-500 text-xl md:text-2xl font-bold">
                    ✕
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden xl:table-cell">
                  <span className="text-red-500 text-xl md:text-2xl font-bold">
                    ✕
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden xl:table-cell">
                  <span className="text-red-500 text-xl md:text-2xl font-bold">
                    ✕
                  </span>
                </td>
              </tr>

              {/* (3) No hidden/complex fees */}
              <tr className="border-b border-gray-700">
                <td className="p-3 md:p-4">No hidden/complex fees</td>
                <td className="p-3 md:p-4 bg-gray-800 text-center">
                  <span className="text-green-400 text-xl md:text-2xl font-bold">
                    ✓
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden md:table-cell">
                  <span className="text-red-500 text-xl md:text-2xl font-bold">
                    ✕
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden lg:table-cell">
                  <span className="text-red-500 text-xl md:text-2xl font-bold">
                    ✕
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden xl:table-cell">
                <span className="text-green-400 text-xl md:text-2xl font-bold">
                    ✓
                  </span>
                </td>

                <td className="p-3 md:p-4 text-center hidden xl:table-cell">
                  <span className="text-red-500 text-xl md:text-2xl font-bold">
                    ✕
                  </span>
                </td>
              </tr>

              {/* (4) Concurrent searches */}
              <tr>
                <td className="p-3 md:p-4">Max concurrency (based on plan)</td>
                <td className="p-3 md:p-4 bg-gray-800 text-center text-base md:text-lg font-semibold">
                  50,000
                </td>

                <td className="p-3 md:p-4 text-center font-semibold hidden md:table-cell">
                  3-50
                </td>

                <td className="p-3 md:p-4 text-center font-semibold hidden xl:table-cell">
                  1-50
                </td>

                <td className="p-3 md:p-4 text-center font-semibold hidden xl:table-cell">
                  20-200
                </td>

                <td className="p-3 md:p-4 text-center font-semibold hidden xl:table-cell">
                  25-256
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>


      {/* Contact Us - Updated */}  
      <section className="max-w-7xl w-full px-6 mt-10 mb-20" id="contact-us">
        <div className="rounded-xl p-6 md:p-8">
          <div className="mb-4 flex items-center space-x-2">
            <h3 className="text-[3em]">Let’s Connect</h3>
            <span className="text-[3em]">🤝</span>
          </div>

          <p className="text-gray-400 mb-6 max-w-2xl">
            Whether you're a developer, investor, or integrator — we’d love to hear from you.
            Drop your email below and we’ll reach out.
          </p>

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              const formData = { email, role };

              try {
                const response = await fetch("https://submit-form.com/OOy1NWTF1", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(formData),
                });

                setSuccessMessage("Thanks! We'll be in touch.");
                setEmail("");
                setRole("Developer");
              } catch {
                setSuccessMessage("Thanks! We'll be in touch.");
                setEmail("");
                setRole("Developer");
              }
            }}
            className="flex flex-col md:flex-row items-start md:items-center gap-4 md:w-[80%]"
          >
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="px-4 py-3 border border-gray-700 rounded-md bg-[#111] text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 w-full md:w-[230px]"
              required
            >
              <option value="Investor">Developer</option>
              <option value="Investor">Investor</option>
              <option value="Integration Partner">Integration Partner</option>
            </select>

            <input
              id="email-input"
              type="email"
              placeholder="your@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-4 py-3 border border-gray-700 rounded-md bg-[#111] text-white placeholder-gray-500 w-full md:w-[280px] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
              required
            />

            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 transition px-5 py-3 rounded-md font-medium text-white"
            >
              Share Email
            </button>
          </form>

          {successMessage && <p className="text-green-400 mt-4">{successMessage}</p>}
        </div>
      </section>

        

    </section>
  );
}

export default Home;